import styled from "styled-components";

import { sizes } from "utility/device";
import { genCustomPropStyle, genSizeStyle } from "src/styles/globalStyle";

export const StyledHeroContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;

  @media (max-width: ${sizes.mediumS}) {
   flex-direction: column;
   align-items: flex-start;
   gap: 60px;
  }
`;

export const StyledHeroTitle = styled.h1`
  ${genCustomPropStyle({
    propName: "margin-bottom",
    maxSize: 180,
    minSize: 20,
  })}
  display: inline-block;
  

  @media (max-width: ${sizes.mediumS}) {
    margin-bottom: 0;
   }
`;

export const StyledHeroImage = styled.div<{ url: string; mobileUrl?: string; }>`
  ${genSizeStyle({
      maxWidth: 800,
      maxHeight: 900,
      minWidth: 246,
      minHeight: 276.75,
  })}
  background-image: ${({ url }) => `url(/${url})`};
  background-position: bottom-right;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 40vw;

  @media (max-width: ${sizes.mediumS}) {
    width: 260px;
    height: 260px;
    max-height: 260px;
    align-self: center;
    background-image: ${({ url, mobileUrl }) => `url(/${mobileUrl ?? url})`};
  }
`;
