import styled from "styled-components";
import { Link } from "gatsby";

import Dot from "src/images/dot.svg";
import HoverDot from "src/images/hoverDot.svg";
import Arrow from "src/images/arrow.svg";
import ActiveDot from "src/images/whiteDot.svg";
import { theme } from "utility/theme";
import { sizes } from "utility/device";
import { ColumnContainer, genCustomPropsStyle } from "src/styles/globalStyle";

export const StyledActiveDot = styled(ActiveDot)`
  margin: 0 !important;
  padding-top: 2px;
`;

export const StyledActiveLinkWrapper = styled.div`
  display: flex;
  gap: 4px;
  justify-content: flex-start !important;
  align-items: center;
`;

export const StyledActiveLink = styled(Link)`
  font-size: 32px !important;
  line-height: 38.4px !important;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMainContainer = styled.div<{
  "data-background": string;
  "data-header-background": string;
  "data-fixed": boolean;
  "data-scrolled"?: boolean;
  "data-content-color-change": boolean;

}>`
  z-index: 100;
  position: ${({ "data-fixed": fixed }) => (fixed ? "fixed" : "relative")};
  top: 0;
  left: 0;
  right: 0;
  height: 6.125rem;
  display: flex;
  align-items: center;
  align-content: space-between;
  background: transparent;
  justify-content: center;
  margin-bottom: 58px;
  margin-left: 3.4rem;
  margin-right: 3.4rem;

  div {
    display: flex;
    align-items: center;
    align-content: space-between;
    ${({
      "data-scrolled": scrolled,
      "data-background": background,
      "data-header-background": headerBackground,
      "data-content-color-change": contentColorChange,
    }) => `
      background: ${scrolled ? headerBackground : background};
      color: ${scrolled && contentColorChange ? theme.colors.white : theme.colors.black};
    `}
    padding: 0 22px;
    box-sizing: border-box;
    height: 79px;
    border-radius: 100px;
    left: 0;
    right: 0;
    width: 100%;
    transition: background 0.5s ease, color 0.05s ease;
    max-width: 1920px;
  }

  @media (max-width: ${sizes.l}) {
    display: none;
  }
`;

export const StyledHeaderSeparator = styled.div`
  width: 100%;
  height: 8.125rem;

  @media (max-width: ${sizes.l}) {
    height: 4.563rem;
  }
`;

export const StyledLeftContainer = styled.div<{
  "data-scrolled"?: boolean;
  "data-content-color-change": boolean;
}>`
  display: flex;
  align-content: space-between;
  margin-right: auto;
  flex-shrink: 1;
  max-width: 300px;

  ${({ "data-scrolled": scrolled, "data-content-color-change": contentColorChange }) =>
    scrolled &&
    contentColorChange &&
    `
      svg{
        path {
          transition: fill 0.5s ease;
          fill: ${theme.colors.white};
        }
      }
    `};
`;

export const StyledRightContainer = styled.div`
  display: flex;
  align-content: space-between;
  justify-content: flex-end;
  ${genCustomPropsStyle({
    props: [
      { propName: "gap", maxSize: 64, minSize: 32 },
      { propName: "font-size", maxSize: 24, minSize: 18 },
      { propName: "line-height", maxSize: 28.8, minSize: 23.4 }
    ]
  })}
`;

export const StyledDot = styled(Dot)``;

export const StyledMobileDot = styled(Dot)`
  margin: auto;
  width: 16px;
  height: 16px;
`;

export const StyledHoverDot = styled(HoverDot)<{
  opacity: number;
  display: string;
}>`
  box-sizing: border-box;
`;

export const StyledMobileMainContainer = styled.div<{
  "data-open": boolean;
  background: string;
  "data-header-background": string;
  "data-scrolled": boolean;
}>`
  transition: background 0.5s ease, color 0.05s ease;
  background: ${({ background, "data-open": open, "data-scrolled": scrolled, "data-header-background": headerBackground }) =>
    open ? "transparent" : !open && scrolled ? headerBackground : background};
  border-radius: 100px;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-bottom: 58px;
  margin-top: 8px;
  height: 57px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  min-width: 281px;

  @media (min-width: ${sizes.bigL}) {
    display: none;
  }

  @media all and (max-width: ${sizes.s}) {
    margin-bottom: 40px;
  }
`;

export const StyledLink = styled(Link)`
  position: relative;
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: no-wrap;
  flex-shrink: 0;
  gap: 8px;
`;

export const StyledMobileNavTriger = styled.div<{
  "data-open": boolean;
  "data-scrolled"?: boolean;
  "data-content-color-change": boolean;
}>`
  @media (max-width: ${sizes.l}) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 12px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 100;
    align-items: center;
  }

  transition: background 0.05s ease;

  div {
    width: 1.5rem;
    height: 2px;
    background: ${({ "data-open": open, "data-scrolled": scrolled, "data-content-color-change": contentColorChange }) =>
      open
        ? theme.colors.white
        : scrolled && contentColorChange
        ? theme.colors.white
        : theme.colors.black};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 6px;

    &:first-child {
      transform: ${({ "data-open": open }) => (open ? "rotate(45deg) translateX(-8px) translateY(8px)" : "rotate(0)")};
    }

    &:nth-child(2) {
      opacity: ${({ "data-open": open }) => (open ? "0" : "1")};
      transform: ${({ "data-open": open }) => (open ? "translateX(40px)" : "translateX(0)")};
    }

    &:nth-child(3) {
      transform: ${({ "data-open": open }) => (open ? "rotate(-45deg) translateX(-8px) translateY(-8px)" : "rotate(0)")};
    }
  }
`;

export const StyledMobileNavBar = styled.div<{ open: boolean }>`
  margin: 0;
  position: fixed;
  top: 0px;
  left: ${({ open }) => (!open ? "100%" : 0)};
  width: 100%;
  height: 100%;
  bottom: 0;
  padding: 70px 20px 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: left 0.2s ease-out;
  background: black;
  z-index: 10;
  justify-content: space-between;
  overflow-y: scroll;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      color: ${theme.colors.white};

      @media (max-width: 320px) {
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 25px;
      }
    }
  }
`;

export const StyledMobileDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
`;

export const StyledWhiteWrapper = styled.div`
  z-index: 100;
  position: fixed;
  animation: opacity-animation 300ms linear 0.1s,
    background-change 10ms linear 0.3s;
  opacity: 0;
  animation-fill-mode: both;
  height: 4.563rem;
  top: 0;
  display: flex;
  align-items: center;

  @keyframes background-change {
    from {
      background: transparent;
    }
    to {
      background: black;
    }
  }

  @keyframes opacity-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (min-width: ${sizes.l}) {
    display: none;
  }
`;

export const StyledArrow = styled(Arrow)`
  margin: 6px 0 0 5px;
  @media all and (max-width: ${sizes.smallS}) {
    margin-left: -4px;
  }
`;

export const ExternalLinksContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const LogoAndButtonWrapper = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 16px);
  left: 0;
  padding-left: 8px;
  height: 74px;
  background: transparent;
  z-index: 1000;
  background: ${({ open }) => open ? "black" : "transparent"};
  position: absolute;
  ${({ open }) => open && "transition: background 0.3s"};
  transition-delay: ${({ open }) => !open ? "0s" : "0.2s"};
`;

export const InfoWrapper = styled.div`
  flex: 1;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const FooterInfo = styled(ColumnContainer)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  line-height: 28.8px;
  padding: 60px 0px 24px 0px;
`;

export const StyledBlackLogoWrapper = styled.div<{
  "data-scrolled"?: boolean;
  "data-content-color-change": boolean;
}>`
  ${({ "data-scrolled": scrolled, "data-content-color-change": contentColorChange }) =>
    scrolled &&
    contentColorChange &&
    `
      svg{
        path {
          transition: fill 0.5s ease;
          fill: ${theme.colors.white}
        }
      }
    `};
`;
