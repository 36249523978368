import React from "react";

import Bloomberg from "src/images/bloomberg.svg";
import BusinessInsider from "src/images/businessInsider.svg";
import Cnbc from "src/images/cnbc.svg";
import Forbes from "src/images/forbes.svg";
import Nasdaq from "src/images/nasdaq.svg";
import NewYorker from "src/images/newYorker.svg";
import Tc from "src/images/tc.svg";
import Yahoo from "src/images/yahoo.svg";

import { LogoList } from "components/LogoList/logoList";

export const NoticedIn = () =>  (
  <LogoList
    heading={"Our work was\nfeatured in."}
    logos={ [
      <Forbes />,
      <Tc />,
      <Cnbc />,
      <Nasdaq />,
      <Yahoo />,
      <Bloomberg />,
      <NewYorker />,
      <BusinessInsider />,
    ]}
  />
);
