import React, { useState } from "react";

import ExpandIcon from "src/images/expandIcon.svg";

import {
  StyledIconContainer,
  StyledInput,
  StyledLabel,
  StyledMainContainer,
  StyledTextareaWrapper,
} from "./multiLineTextInputStyle";

type MultilineTextInputProps = {
  label: string;
  ariaLabel: string;
  placeholder: string;
  required?: boolean;
  value: string;
  onChange: (e: string | React.ChangeEvent<any>) => void;
};

const MultilineTextInput = ({
  label,
  ariaLabel,
  placeholder,
  required = true,
  value,
  onChange,
}: MultilineTextInputProps): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <StyledMainContainer>
      <StyledLabel>{label}</StyledLabel>
      {/* <StyledTextareaWrapper> */}
        <StyledInput
          placeholder={placeholder}
          aria-label={ariaLabel}
          required={required}
          rows={!expanded ? 7 : 9}
          value={value}
          onChange={onChange}
        />
      {/* </StyledTextareaWrapper> */}
      <StyledIconContainer
        onClick={() => setExpanded(!expanded)}
        hovered={hovered}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <ExpandIcon />
      </StyledIconContainer>
    </StyledMainContainer>
  );
};

export default React.memo(MultilineTextInput);
