import React, { lazy, Suspense } from "react";

import { StyledSeparator } from "src/components/Layout/layoutStyle";
import SectionAnimation from "src/components/SectionAnimation/sectionAnimation";
import Footer from "src/components/Footer/footer";

import { StyledContainer } from "./homeStyle";
import OurServices from "./OurServices/ourServices";
import ClientList from "./ClientList/clientList";
import { NoticedIn } from "./NoticedIn/noticedIn";
import CubeSpinner from "components/CubeSpinner/cubeSpinner";
import { theme } from "utility/theme";
import Hero from "components/Hero/hero";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const ReachPotential = lazy(() => import("./ReachPotential/reachPotential"));
const ReviewCarousel = lazy(() => import("./ReviewCarousel/reviewCarousel"));
const SparkYourInnovation = lazy(() =>
  import("./SparkYourInnovation/sparkYourInnovation")
);

export const Home = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMedScreen = !breakpoints.midS;

  return (
    <StyledContainer>
      <SectionAnimation>
        <Hero
          title={
            <>
              We build&nbsp;
              <CubeSpinner
                display={["awesome", "worthy", "smart", "elegant"]}
                background={theme.colors.homeBackground}
                maxWidth={467}
                maxHeight={90}
                minHeight={38}
                minWidth={155}
              />
              <br />
              digital products.
            </>
          }
          image={{
            src: "hero/homeHero.png",
            mobileSrc: "hero/homeHeroMobile.png",
          }}
        />
      </SectionAnimation>
      {isMedScreen && (
        <StyledSeparator />
      )}


      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <OurServices />
        </SectionAnimation>
      </Suspense>

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <ClientList viewOurWork />
        </SectionAnimation>
      </Suspense>

      <StyledSeparator />

      <NoticedIn />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <ReachPotential />
        </SectionAnimation>
      </Suspense>

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <ReviewCarousel />
        </SectionAnimation>
      </Suspense>

      <StyledSeparator />

      <Suspense fallback={<div></div>}>
        <SectionAnimation>
          <SparkYourInnovation />
        </SectionAnimation>
      </Suspense>

      <StyledSeparator />
      <Footer />
    </StyledContainer>
  );
};

export default Home;
