import { isMobile } from "react-device-detect";

export const animationWindowScrollTreshhold = 50;
export const heroAnimationWindowScrollTreshhold = 300;

export const animationDuration = 4000;

export const defaultSectionOffset = 400;
export const sectionAnimationDuration = 700;
export const sectionAnimationDelay = 50;

export const companyAnimationDelay = 2000;

export const clientListAnimationDuration = 500;
export const clientSliderInterval = isMobile ? 2500 : 4000;
export const onClientChangeOffset = 50;

export const reachedFooterIndicator = 1500;

export const HEROCU_PARAGRAPH_DELAY = 400;

export const PHILOSOPHY_PARAGRAPH_DELAY = 700;

export const phoneRegex = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const MAX_FILE_SIZE = 26214400; // 25MB
export const SUPPORTED_FILE_TYPE = "application/pdf";

export const SUGGESTIONS_NUM = 2;

export const MAX_VIEWPORT_WIDTH = 1920;

export const herocuParagraphs = [
  "We're here for the idea people,",
  "to spark your innovation,",
  "to nurture your ideas,",
  "to extend your horizons,",
  "and to build a collective future.",
];

export const menuItemTitle = {
  HOME: "Home",
  PRODUCT_INNOVATION: "Product Innovation",
  TECH_EXCELLENCE: "Tech Excellence",
  TECH_RADAR: "Tech Radar",
  CAREERS: "Careers",
  KNOWLE: "Knowle",
  PRIVACI: "Privacy",
  COMPANY: "Company",
  CONTACT: "Contact",
  WORK: "Our Work",
  BLOG: "Blog",
  SCALA_SERVICES: "Scala Services",
};

export const externalLinkTitle = {
  INSTAGRAM: "Instagram",
  LINKEDIN: "LinkedIn",
  TWITTER: "X",
};

export const ourServicesTitle = {
  DISCOVERY: "Product Discovery",
  ENGINEERING: "Software Engineering",
  ANALYTICS: "Business Analytics",
};

export const accessibility = {
  CAREERS_FIND_OUT_MORE: "Find out more about the position.",
  DESIGN_RUSH: "Open the Design Rush review.",
  INTERESTS: "List your personal interests or hobbies.",
  PROJECTS: "List any personal projects you have.",
  OTHERS: "Enter any additional information you would like to share with us.",
  MESSAGE: "Provide a brief explanation of how we can assist you.",
  PROJECT: "Tell us about yor project so we can help you accelerate your product development.",
}

export const techStackTitles = {
  PLATFORMS: "Platforms",
  DATABASES: "Databases",
  LANGUAGES: "Languages",
  TOOLS: "Tools",
};

export const BLOG_PAGE_SIZE = 8;
export const DEFAULT_PAGE = 1;

export const SITE_URL = "https://www.lambdaworks.io";

export const STRAPI_STORAGE_URL = process.env.STRAPI_GQL_DEV_ENDPOINT?.replace("/graphql", "");
