import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { footerPages } from "utility/lists";
import { MobileMenuItem } from "utility/models";

import {
  StyledFooter,
  StyledLink,
  StyledLinkGrid,
  StyledLogo,
  StyledMidContencWrapper,
  StyledContactInfo,
  StyledMedRowWrapper,
  StyledMedColWrapper,
} from "./footerStyle";
import FooterBottomMobile from "./footerBottomMobile";

const Footer = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMinWidthS = breakpoints.s;
  const isNarrow = breakpoints.m;

  return (
    <StyledFooter>
      <StyledMedRowWrapper>
        <StyledMedColWrapper>
          <StyledContactInfo>
            office@lambdaworks.io<br />+381 21 303 53 99<br />Miše
            Dimitrijevića 12<br />Novi Sad, Serbia
          </StyledContactInfo>
          {isMinWidthS && (
            <StyledMidContencWrapper>
              <StyledLinkGrid>
                {footerPages.map((item: MobileMenuItem) =>
                  <StyledLink to={item.route} key={item.name}>
                    {item.name}
                  </StyledLink>
                )}
              </StyledLinkGrid>
              
            </StyledMidContencWrapper>
          )}
        </StyledMedColWrapper>
        <StyledLogo isHidden={!isNarrow} />
      </StyledMedRowWrapper>
     
      <FooterBottomMobile mode="dark" />
    </StyledFooter>
  );
};

export default React.memo(Footer);
