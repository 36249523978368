import * as Yup from "yup";

import { phoneRegex } from "utility/constants";

export const ContactSchema = Yup.object({
  email: Yup.string()
    .email("Email is incorrect.")
    .required("Email is required."),
  message: Yup.string()
    .required("Message is required."),
});
